import React, { Component } from "react";
import i18next from "i18next";
import { Link } from 'react-router-dom'
import cookie from 'js-cookie'
import { connect } from 'react-redux';
import { languageDetector } from "../../../src/translations/languageDetector";

// const lang = localStorage.getItem('lang') || 'en'
// var lang = splitUrl[3] == "ar" ? "ar" : "en"
// console.log("lang on headr", lang)
var url = window.location.href;
var url = url.split("?")[0]
var splitUrl = url.split("/")
const lang = languageDetector();

class CategoriesHomeComponet extends Component {
  render() {

    // const lang = localStorage.getItem("lang") || "en";
    const token = cookie.get('token');
    return (
      <div className="section pt-0">
        <div className="container">
          <div className="section-title-wrap section-header">
            {/* <h5 className="custom-primary">{i18next.t("cat_type")}</h5> */}
            {/* <h2 lang={lang == "en" ? "en" : "ar"} className="title">{i18next.t("browsebycategory")}</h2> */}
          </div>

          <div style={{ margin: '5px' }} className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 col-mob">
              <a
                href={lang == "en" ? "/for-rent/apartments/uae" : "/ar/for-rent/apartments/uae"}
                className="acr-category category-2">
                <svg className="icon broker">
                  <use href="/sprite.svg#apartments"></use>
                </svg>
                <div className="acr-category-body">
                  <h5> {i18next.t("apartments")} </h5>
                  {/* <span>90 Listings</span> */}
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-4 col-mob">
              <a
                href={lang == "en" ? "/for-rent/residential-villa/uae" : "/ar/for-rent/residential-villa/uae"}
                className="acr-category category-2">
                <svg className="icon broker">
                  <use href="/sprite.svg#villas"></use>
                </svg>
                <div className="acr-category-body">
                  <h5> {i18next.t("villas")} </h5>
                  {/* <span>433 Listings</span> */}
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-4 col-mob">
              <a
                href={lang == "en" ? "/for-rent/office/uae" : "/ar/for-rent/office/uae"}
                className="acr-category category-2">
                <svg className="icon broker">
                  <use href="/sprite.svg#offices"></use>
                </svg>
                <div className="acr-category-body">
                  <h5> {i18next.t("offices")} </h5>
                  {/* <span>221 Listings</span> */}
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-4 col-mob">
              <a
                href={lang == "en" ? "/for-rent/warehouse/uae" : "/ar/for-rent/warehouse/uae"}
                className="acr-category category-2">
                <svg className="icon broker">
                  <use href="/sprite.svg#warehouses"></use>
                </svg>
                <div className="acr-category-body">
                  <h5> {i18next.t("warehouses")} </h5>
                  {/* <span>185 Listings</span> */}
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-4 col-mob">
              <a
                href={lang == "en" ? "/for-rent/hotel-apartments/uae" : "/ar/for-rent/hotel-apartments/uae"}
                className="acr-category category-2">
                <svg className="icon broker">
                  <use href="/sprite.svg#hotel-apartments"></use>
                </svg>
                <div className="acr-category-body">
                  <h5>{i18next.t("hotelapartments")}</h5>
                  {/* <span>230 Listings</span> */}
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-4 col-mob">
              <a
                href={lang == "en" ? "/for-sale/commerical-land/uae" : "/ar/for-sale/commerical-land/uae"}
                className="acr-category category-2">
                <svg className="icon broker">
                  <use href="/sprite.svg#industrial-land"></use>
                </svg>
                <div className="acr-category-body">
                  <h5> {i18next.t("lands")} </h5>
                  {/* <span>365 Listings</span> */}
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="section section-padding pt-0">
          <div className="container">

            <div className="row">
              <div className="col-lg-6">
                <div className="cta cta-2 item1">
                  {/* <i className="flaticon-sales-agent"></i> */}
          
                  <div className="cta-body">
                    <h4>{i18next.t("buyyinghome")}</h4>
                    <p>   {i18next.t("buinghomeText")} </p>
                    <a href={lang == "en" ? "/for-sale/apartments/uae" : "/ar/for-sale/apartments/uae"} className="btn-link">{i18next.t("findoutmore")}
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="cta cta-2 item2">
                  {/* <i className="flaticon-sold"></i> */}
                  <div className="cta-body">
                    <h4>{i18next.t("sellinghome")}</h4>
                    <p>{i18next.t("sellinghomeText")} </p>
                    {!token ? <a href="#login" data-bs-toggle="modal" className="btn-link">{i18next.t("registerhere")} {lang == 'en' ? <i className="fas fa-arrow-right"></i> : <i className="fas fa-arrow-left"></i>} </a> : null}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div style={{ margin: "0 auto" }} className="col-lg-6 center">
                <div className="cta cta-2 item1 center">
                  <i className="flaticon-sales-agent"></i>

                  <div className="cta-body">
                    <h4>{i18next.t("areyoupropertyowner")}</h4>
                    <p>{i18next.t("doyouwanttosell")}</p>
                    <a data-bs-toggle="modal" href="#login" className="btn-link">{i18next.t("registerhere")} {lang == 'en' ? <i className="fas fa-arrow-right"></i> : <i className="fas fa-arrow-left"></i>} </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row">
              <div style={{ margin: "0 auto" }} className="col-lg-6 center">
                <div className="cta cta-2 item1 center">
                  <i className="flaticon-sales-agent"></i>
                  <div className="cta-body">
                    <h4 lang={lang == "en" ? "en" : "ar"} >{i18next.t("areyoupropertyowner")}</h4>
                    <h5 lang={lang == "en" ? "en" : "ar"} >{i18next.t("doyouwanttosell")}</h5>
                    <a data-bs-toggle="modal" href="#login" className="btn-link">{i18next.t("registerhere")} {lang == 'en' ? <i className="fas fa-arrow-right"></i> : <i className="fas fa-arrow-left"></i>} </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* ============================ Smart Testimonials ================================== */}
        {/* <section className="gray-simple">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-md-8">
                  <div className="sec-heading center">
                    <h2>Good Reviews By Clients</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="item-slide space">
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-1.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Susan D. Murphy</h5>
                              <div className="_ovr_posts"><span>CEO, Leader</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.7</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-1.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-2.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Maxine E. Gagliardi</h5>
                              <div className="_ovr_posts"><span>Apple CEO</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.5</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-2.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-3.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Roy M. Cardona</h5>
                              <div className="_ovr_posts"><span>Google Founder</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.9</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-3.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-4.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Dorothy K. Shipton</h5>
                              <div className="_ovr_posts"><span>Linkedin Leader</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.7</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-4.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-5.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Robert P. McKissack</h5>
                              <div className="_ovr_posts"><span>CEO, Leader</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.7</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-5.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
      </div>
    );
  }
}

export default CategoriesHomeComponet;
