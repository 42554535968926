import {combineReducers} from 'redux'
import AuthReducer from './AuthReducer'
// import PropertyReducer from './propertyReducer'
// import languageReducer from './languageReducer';

const rootReducer = combineReducers({
    auth:AuthReducer,
    // property: PropertyReducer,

})

export default rootReducer

