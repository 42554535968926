import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import axios from "axios";
import { baseurl, baseurlImg } from "../../Components/BaseUrl";
import User from "./User";
import { connect } from "react-redux";
import cookie from "js-cookie";
import $ from "jquery";
import ResponsiveDisplayAd from "../../Components/Adsense/ResponsiveDisplayAd";
import { store } from "../../store";
import { setLanguage } from '../../../src/reducers/languageSlice';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      isLoading: false,
      check_status: false,
      input: {},
      validationerrors: {},
      showModal: false,
    }
  }
 
  changeLanguage = (lang) => {
    // const { i18n, setLanguage } = this.props;
    const newLanguage = lang === 'en' ? 'ar' : 'en';
    // setLanguage(newLanguage);
    // i18n.changeLanguage(newLanguage);
//console.log("button change lang", lang)
    // const currentPath = window.location.pathname;
    const { setLanguage } = this.props;
    setLanguage(newLanguage);
    i18next.changeLanguage(newLanguage);

    const currentPath = window.location.pathname.replace(/^\/(ar|en)/, '');
    if (lang === 'ar') {
      this.props.history.push(`/ar${currentPath}`);
    } else {
      this.props.history.push(`${currentPath}`);
    }
    // if (lang == "ar") {
    //   // Remove "/ar" segment for English version
    //   const newPath = currentPath.replace('/ar', '');
    //   // window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    // } else if (currentPath.startsWith('/ar')) {
    //   // Remove "/ar" segment for English version
    //   const newPath = currentPath.replace('/ar', '');
    //   // window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    // } else {
    //   // Add "/ar" segment for Arabic version
    //   const newPath = `/ar${currentPath}`;
    //   // window.location.href = newPath;
    // }
  };

  toggleLanguage = () => {
    const { isArabic } = this.props;
    const currentPath = window.location.pathname;

    if (isArabic) {
      // Remove "/ar" segment for English version
      const newPath = currentPath.replace('/ar', '');
      window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    } else if (currentPath.startsWith('/ar')) {
      // Remove "/ar" segment for English version
      const newPath = currentPath.replace('/ar', '');
      window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    } else {
      // Add "/ar" segment for Arabic version
      const newPath = `/ar${currentPath}`;
      window.location.href = newPath;
    }
  };
  handleLogout = () => {
    this.props.logout();
  };

  componentDidMount() {
    // console.log("props on mount nav", this.props)
    $("#aside-trigger").on("click", function () {
      $("#humbergerMenu").toggleClass("open");
      $("body").toggleClass("aside-open");
    });
    $(".main-aside .menu-item-has-children > a").on("click", function (e) {
      e.preventDefault();
      var submenu = $(this).next(".submenu");

      if ($(this).parent().hasClass("active")) {
        submenu.slideUp(200);
        $(this).parent().siblings().find(".submenu").slideUp(200);
        $(this).parent().removeClass("active");
      } else {
        $(this)
          .closest("ul")
          .find(".menu-item-has-children")
          .find(".submenu")
          .slideUp(200);
        $(this)
          .closest("ul")
          .find(".menu-item-has-children")
          .removeClass("active");
        $(this).parent().addClass("active");
        submenu.slideDown(200);
      }
    });
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  handleHideModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const userdata = store.getState((state) => state);
    let user = userdata.auth.user;
    const token = userdata.auth.token;
    const lang = localStorage.getItem("lang") || "en";
    const username = user.name;
    const username_ar = user.name_ar;
    const profile = user.profile;
    const email = user.email;
    var language = lang == "ar" ? "/ar" : "";

    //console.log("user", token)

    return (
      <div>
        <nav className="navbar">
          <div className="container">
            <div className="header-controls">
              <div id="aside-trigger" className="aside-toggler aside-trigger">
                <span />
                <span />
                <span />
              </div>
            </div>
            <ul className="nav-menu nav-menu-social align-to-right d-none d-lg-block d-md-block">
              {token ? (
                <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <div className="btn-group account-drop p-0">
                        <button
                          type="button"
                          className="btn btn-order-by-filt"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          {profile == null?<img style={{ width: '50px', height: '50px', borderRadius: '50%' }}  src={baseurlImg + "/uploads/profiles/avatar.png"} className="avater-img" alt />:
                          <img style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={baseurlImg + "/uploads/profiles/" + profile} className="avater-img" alt />}
                        </button>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={language + "/my-ads"}><i className="fa fa-comment" /> {i18next.t("myads")}</Dropdown.Item>
                      <Dropdown.Item href={language + "/profileSetting"}> <i className="fa fa-cog" /> {i18next.t("setting")}</Dropdown.Item>
                      <Dropdown.Item onClick={this.handleLogout}><i className="fa fa-sign-out" /> {i18next.t("logout")}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              ) : (
                <li>
                  <a
                    href="#"
                    onClick={this.handleShowModal}
                    className="alio_green"
                  >
                    <i className="fa fa-user"></i>
                    <span className="dn-lg">{i18next.t("login")}</span>
                  </a>
                </li>
              )}
            </ul>
            <ul className="navbar-nav">
              <li className="menu-item menu-item-has-children">
                <Link to={language + "/"}>{i18next.t("home")}</Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <a
                  href={ lang == "en"
                        ? "/for-rent/properties/uae"
                        : "/ar/for-rent/properties/uae"}                >
                  {i18next.t("properties")}
                </a>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to={language + "/agency/"}>{i18next.t("agencies")}</Link>
              </li>
              <li className="menu-item menu-item-has-children"></li>
              <li className="menu-item menu-item-has-children">
                <Link to={language + "/blogs"} id="blogs-menu">
                  {i18next.t("blogs")}
                </Link>
                <ul className="submenu">
                  <li className="menu-item">
                    <Link to={language + "/area-guide/"}>
                      {i18next.t("areaguide")}
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to={language + "/building-guide/"}>
                      {i18next.t("buildingguide")}
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to={language + "/tips/"}>{i18next.t("tips")}</Link>
                  </li>
                  <li className="menu-item">
                    <Link to={language + "/tourist-destination/"}>
                      {i18next.t("touristdestination")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to={language + "/contact"}>{i18next.t("contact")}</Link>
              </li>
            </ul>
            <div className="language-selector d-xl-none d-lg-none d-xl-block" style={{ margin: "0 7px" }}>
              {/* <button style={{ padding: '2px' }} className="dropdown-item" onClick={() => this.changeLanguage(lang)}>
                {lang === 'en' ? 'العربية' : 'English'}
              </button> */}
               {lang == "en" ? (
                <button onClick={this.toggleLanguage} style={{ padding: "2px", fontSize: "1rem", color: "#707e9c" }} className="dropdown-item" href="">
                  العربية
                </button>
                ) : (
                <button
                  onClick={this.toggleLanguage}
                  style={{ padding: "2px", fontSize: "1rem", color: "#707e9c" }}
                  className="dropdown-item" href="">
                  English
                </button>
              )}
            </div>
            <div className="submitListing d-non d-lg-block d-md-block">
              {token == "" || token == null ? (
                <button className="submit" onClick={this.handleShowModal} >
                  {i18next.t("submitlisting")} 
                </button>
                ) : (
                <Link 
                  to={lang == "en" ? "/add-listing" : "/ar/add-listing"}
                  className=""
                >
                  {i18next.t("submitlisting")} 
                </Link>
              )}
            </div>
            <Link to={"/" + language} className="navbar-brand">
              <img width="48" height="48" src="/frontendUI/img/logo-Find.png" alt="Find Properties" />
            </Link>
          </div>
        </nav>
        <User show={this.state.showModal} onHide={this.handleHideModal} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);