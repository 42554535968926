import React, { Component } from "react";
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import $ from 'jquery'
import i18next from 'i18next'

export default class HomePageBottomAr extends Component {
      render() {
        const lang = "ar";
        return (
          <footer className="acr-footer footer-dark">
            <div className="container">
              <Tab.Container defaultActiveKey="#pills-abu" id="locations-tab">
                <Nav variant="pills" className="mb-3" role="tablist">
                  <Nav.Item>
                    <Nav.Link eventKey="#pills-abu">{i18next.t("abudhabi")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#pills-dub">{i18next.t("dubai")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#pills-shr">{i18next.t("sharjah")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#pills-ajm">{i18next.t("ajman")}</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="#pills-umq">{i18next.t("umalq")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#pills-rak">{i18next.t("rak")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#pills-fuj">{i18next.t("fuj")}</Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="#pills-abu">
                        <div className="row">
                        <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>{i18next.t("popularsearches")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/properties/abu-dhabi">عقارات للإيجار في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi">شقق للإيجار في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/studio-apartments/abu-dhabi">شقق استوديو للإيجار في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi">شقق مفروشة للإيجار في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi">شقق للإيجار في أبوظبي</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/hamdan-street">شقق للإيجار في شارع حمدان</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/mohammed-bin-zayed-city">شقق للإيجار في مدينة محمد بن زايد</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/al-reem-island">شقق للإيجار في جزيرة الريم</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/mussafah">شقق للإيجار في مصفح</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/corniche-area">شقق للإيجار في منطقة الكورنيش</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/al-bateen">شقق للإيجار في البطين</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/mussafah/shabiya">شقق للإيجار في الشعبية</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/al-muroor">شقق للإيجار في المرور</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/abu-dhabi/tourist-club-area-tca">شقق للإيجار في منطقة النادي السياحي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/residential-villa/abu-dhabi">فلل للإيجار في أبوظبي</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/shops/abu-dhabi">محلات للإيجار في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/office/abu-dhabi">مكاتب للإيجار في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-sale/properties/abu-dhabi">عقارات للبيع في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-sale/apartments/abu-dhabi">شقق للبيع في أبوظبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-sale/residential-villa/abu-dhabi">فلل للبيع في أبوظبي</a></li>
                            </ul>
                        </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#pills-dub">
                        <div className="row">
                        <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>{i18next.t("popularsearches")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/properties/dubai">عقارات للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/studio-apartments/dubai">شقق استوديو للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق مفروشة للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/studio-apartments/dubai">شقق استوديو للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق مفروشة للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/properties/dubai">عقارات للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/studio-apartments/dubai">شقق استوديو للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق مفروشة للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                            <ul>
                            <li><a href="https://findproperties.ae/ar/for-rent/properties/dubai">عقارات للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/studio-apartments/dubai">شقق استوديو للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق مفروشة للإيجار في دبي</a></li>
                            <li><a href="https://findproperties.ae/ar/for-rent/apartments/dubai">شقق للإيجار في دبي</a></li>
                            </ul>
                        </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#pills-shr">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>عمليات البحث الشائعة</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-rent/apartments/sharjah">شقق للإيجار في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-rent/residential-villa/sharjah">فلل للإيجار في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-sale/residential-villa/sharjah">فلل للبيع في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-sale/apartments/sharjah">شقق للبيع في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-rent/studio-apartments/sharjah">استوديو للإيجار في الشارقة</a></li>
                            </ul>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>المناطق الشعبية</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/rolla-area">شقق للإيجار في منطقة الرولة</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/al-majaz">شقق للإيجار في المجاز</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/muwailih-commercial">شقق للإيجار في مويلح التجاري</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/al-qasimia">شقق للإيجار في القاسمية</a></li>
                                <li><a href="https://findproperties.ae/for-sale/residential-villa/sharjah/al-azra">فلل للبيع في العزرة</a></li>
                            </ul>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>المناطق الرائجة</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-sale/properties/sharjah">عقارات للبيع في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-sale/residential-land/sharjah/hoshi">أراضي سكنية للبيع في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-sale/properties/sharjah/hoshi">عقارات للبيع في الحوشي</a></li>
                                <li><a href="https://findproperties.ae/for-rent/warehouse/sharjah/al-saja">مستودعات للإيجار في السجع</a></li>
                                <li><a href="https://findproperties.ae/for-rent/commerical-land/sharjah">أراضي تجارية للإيجار في الشارقة</a></li>
                            </ul>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>البحث الرائج</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-rent/properties/sharjah">عقارات للإيجار في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-rent/properties/sharjah/abu-shagara">عقارات للإيجار في أبو شغارة</a></li>
                                <li><a href="https://findproperties.ae/for-rent/office/sharjah">مكاتب للإيجار في الشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-rent/properties/sharjah/industrial-area">عقارات للإيجار في المنطقة الصناعية بالشارقة</a></li>
                                <li><a href="https://findproperties.ae/for-rent/properties/sharjah/emirates-industrial-city">عقارات للإيجار في المدينة الصناعية بالإمارات</a></li>
                            </ul>
                            </div>
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#pills-ajm">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>عمليات البحث الشائعة</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-rent/properties/ajman">عقارات للإيجار في عجمان</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman">شقق للإيجار في عجمان</a></li>
                                <li><a href="https://findproperties.ae/for-rent/residential-villa/ajman">فلل للإيجار في عجمان</a></li>
                                <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman">فلل للبيع في عجمان</a></li>
                                <li><a href="https://findproperties.ae/for-sale/apartments/ajman">شقق للبيع في عجمان</a></li>
                            </ul>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>المناطق الشعبية</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-rent/studio-apartments/ajman">استوديو للإيجار في عجمان شهرياً</a></li>
                                <li><a href="https://findproperties.ae/for-rent/studio-apartments/ajman">استوديو للإيجار في عجمان سنوياً</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-bustan">شقق للبيع في البستان</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-nuaimiya">شقق للإيجار في النعيمية</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-rashidiya">شقق للإيجار في الراشدية</a></li>
                            </ul>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>المناطق الرائجة</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/corniche-ajman">شقق للإيجار في كورنيش عجمان</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-sawan">شقق للإيجار في السوان</a></li>
                                <li><a href="https://findproperties.ae/for-sale/residential-land/ajman">أراضي للبيع في عجمان</a></li>
                                <li><a href="https://findproperties.ae/for-sale/properties/ajman">عقارات للبيع في عجمان</a></li>
                                <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman/al-yasmeen">فلل للبيع في الياسمين</a></li>
                            </ul>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4">
                            <b>البحث الرائج</b>
                            <ul>
                                <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman/al-rawda">فلل للبيع في الروضة</a></li>
                                <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman/al-yasmeen">فلل للبيع في الياسمين</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-nuaimiya">شقق للإيجار في النعيمية</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-bustan">شقق للبيع في البستان</a></li>
                                <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-rashidiya">شقق للإيجار في الراشدية</a></li>
                            </ul>
                            </div>
                        </div>
                        </Tab.Pane>
              </Tab.Content>
              </Tab.Container>
            </div>
          </footer>
        );
      }
    }
    