// export const baseurl ="https://services.findproperties.ae/api/"
// export const baseurlImg ="https://services.findproperties.ae/public"
// export const websitebaseurl ="https://findproperties.ae/"

// export const baseurl ="https://servicesstagingfindproperties.findproperties.ae/api/"
// export const baseurlImg ="https://servicesstagingfindproperties.findproperties.ae/public"
// export const websitebaseurl ="https://findproperties.ae/"

//export const baseurl = "http://10.39.1.135/backend/public/api/";
// https:///
// export const baseurl = "https://services.findproperties.ae/api/";
export const baseurl = "https://services.findproperties.ae/api/";
export const baseurlImg = "https://findproperties.s3.me-south-1.amazonaws.com";
export const websitebaseurl = "https://findproperties.ae/";
// export const bannerImage = "../../public/frontendUI/img/banner.jpg"

// export const baseurl ="http://10.39.1.76/findproperties/public/api/"
// export const baseurlImg ="http://10.39.1.76/findproperties/public/"
// export const websitebaseurl = "https://localhosg"
