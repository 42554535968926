import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import i18next from 'i18next'
import { store } from '../../store'
import { connect } from "react-redux";
import { baseurlImg } from '../../Components/BaseUrl'
import User from "./User";

var url = window.location.href;
var url = url.split("?")[0]
var splitUrl = url.split("/")
// const lang = localStorage.getItem('lang') || 'en'
var lang = splitUrl[3] == "ar" ? "ar" : "en"
// console.log("lang on headr", lang)

var language = lang == "en" ? "" : "/ar"



// console.log("is this person logged in:", loggedIn)
export class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    }
  }
  closeFilter = () => {
    $("#humbergerMenu").toggleClass('open');
    $("body").toggleClass('aside-open');
  }
  handleLogout = () => {
    this.props.logout();
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  handleHideModal = () => {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    // $(function() {
    $(document).on('click', function (event) {
      if (!$(event.target).closest('#humbergerMenu').length && $("#humbergerMenu").hasClass("open")) {
        //alert("clicked Outside")
        //$("#humbergerMenu").toggleClass('open');
        // $("body").toggleClass('aside-open'); 
      }
    });
    document.addEventListener('click', ({ target }) => {
      if (!target.closest('#aside-trigger')) {
        $("#humbergerMenu").removeClass('open');
        $("body").removeClass('aside-open');
        //   alert("Clicked Outsode")
      }
    })

    // });

  }
  render() {
    const userdata = store.getState((state) => state);
    const user = userdata.auth.user;
    const profile = user.profile;
    const name = user.name;
    const loggedIn = userdata.auth.loggedIn;
    // console.log("image", profile)
    return (
      <div>
        <aside id="humbergerMenu" className="main-aside">
          <div className="aside-title">
            <div className="aside-controls aside-trigger">
              {/* <h4>{lang=="en"? "Menu": "قائمة" }</h4> */}
              {loggedIn ?
                <button type="button" className="btn btn-order-by-filt" style={{ width: "100%" }} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {profile == null?<img  style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={baseurlImg + "/uploads/profiles/avatar.png"} className="avater-img" alt />:
                   <img  style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={baseurlImg + "/uploads/profiles/" + profile} className="avater-img" alt />}
                  <div style={{ display: 'block', marginLeft: '5px', fontWeight: 'bold' }}>{name}</div>
                </button>
                :
                <button type="button" className="btn btn-order-by-filt" style={{ width: "100%" }} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <a id="loginForModal" data-bs-toggle="modal" href="#login" role="button" className="alio_green">
                    <span className="dn-lg"> {i18next.t("login")}</span>
                  </a>
                </button>
              }
            </div>
          </div>
          <div className="aside-scroll">
            <ul>
              {/* Pages Start */}
              <li className="menu-item">
              </li>
              <li className="menu-section-title"></li>
              <li onClick={this.closeFilter} className="menu-item">
                <Link to={language + "/"}>{i18next.t("home")}</Link>
              </li>
              <li onClick={this.closeFilter} className="menu-item"></li>
              <li onClick={this.closeFilter} className="menu-item">
                <a href={lang == "en"?language + "/for-rent/properties/uae" : language + "/for-rent/properties/uae" }
                  > {i18next.t("search")}</a>
              </li>
              <li onClick={this.closeFilter} className="menu-item">
                <Link to={language + "/agency"}> {i18next.t("agency")}</Link>
              </li>
              {loggedIn ?
                <li onClick={this.closeFilter} className="menu-item">
                  <Link to={language + "/my-ads"}> {i18next.t("myads")}</Link>
                </li> : null
              }
              {loggedIn ?
                <li onClick={this.closeFilter} className="menu-item">
                  <Link to={language + "/favourites"}>  {i18next.t("favourites")}</Link>
                </li> : null
              }
              {/* <li onClick={this.closeFilter} className="menu-item">
                        <Link to={language +"/message"}> <i className="fa fa-comment" /> {i18next.t("messages")}</Link>
                    </li> */}
              <li onClick={this.closeFilter} className="menu-item">
                <Link to={lang == "en" ? "/blogs" : "/ar/blogs"}>  {i18next.t("blogs")}</Link>
              </li>
              {loggedIn ?
                <li onClick={this.closeFilter} className="menu-item">
                  <Link to={language + "/profileSetting"}>  {i18next.t("setting")}</Link>
                </li> : null
              }
              <li className="menu-item">
                <Link to={language + "/contact"}>{i18next.t("contact")}</Link>
              </li>
              {loggedIn ?
                <li onClick={this.closeFilter} className="menu-item">
                  <a onClick={this.handleLogout}>  {i18next.t("logout")}</a>
                </li>
                : 
                <li onClick={this.handleShowModal} className="menu-item">
                  <a onClick={this.handleShowModal}>  {i18next.t("login")}</a>
                </li>
              }

            </ul>
          </div>
          <User show={this.state.showModal} onHide={this.handleHideModal} />


        </aside>

      </div>
    )
  }
}

// export default MobileMenu

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);

