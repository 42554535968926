import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { store } from './store';
import { setLanguage } from './reducers/languageSlice';

import common_en from './translations/en.json';
import common_ar from './translations/ar.json';
import {languageDetector} from './translations/languageDetector'

// Function to get the initial language from the URL
const lang = languageDetector();

//console.log("intial language on i18", languageDetector())
// Get initial language from URL or Redux store
const initialLanguage = languageDetector();

//console.log("intial lang on redux", initialLanguage)
const resources = {
  en: {
    translation: common_en,
  },
  ar: {
    translation: common_ar,
  },
};

i18n.use(initReactI18next)
.init({
    resources,
    lng: initialLanguage,
    keySeparator:false,
    interpolation:{
        escapeValue:false
    }
});

// Sync i18next language with Redux
// store.subscribe(() => {
//   const state = store.getState();
//   if (i18n.language !== state.language.language) {
//     i18n.changeLanguage(state.language.language);
//   }
// });

// // Set initial language in Redux store if different from the current state
// if (store.getState().language.language !== initialLanguage) {
//   store.dispatch(setLanguage(initialLanguage));
// }

export default i18n;
