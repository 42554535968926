import React, { Component} from 'react'
// import BannerComponent from './CommonPageComponets/BannerComponent'
// import CategoriesHomeComponet from './HomePageComponets/CategoriesHomeComponet'
// import BrowseByCityComponent from './HomePageComponets/BrowseByCityComponent'
// import RecentListing from './HomePageComponets/RecentListing'
// import DownloadOurApp from './HomePageComponets/DownloadOurApp'
import './HomePageComponets/Home.css'
// import FooterComponent from './CommonPageComponets/FooterComponent'
import HomePageBottomEn from './CommonPageComponets/HomePageBottomEn'
import HomePageBottomAr from './CommonPageComponets/HomePageBottomAr'
// import i18next from "i18next";
import { Helmet } from "react-helmet";
// const RecentListing = lazy(() => import('./HomePageComponets/RecentListing'));
// const BrowseByCityComponent = lazy(() => import('./HomePageComponets/BrowseByCityComponent'));
// const CategoriesHomeComponet = lazy(() => import('./HomePageComponets/CategoriesHomeComponet'));
// const DownloadOurApp = lazy(() => import('./HomePageComponets/DownloadOurApp'));
import CategoriesHomeComponet from './HomePageComponets/CategoriesHomeComponet';
import DownloadOurApp from './HomePageComponets/DownloadOurApp';
import { connect } from 'react-redux';
import $ from "jquery";
// import API from "API";
import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../Components/BaseUrl";
import i18next from "i18next";
// import Particles from "react-particles-js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// lazy(() => import('./HomePageComponets/Home.css'));
import API from "../API";
import {locationData} from '../Components/LocationData';

import { languageDetector } from "../../src/translations/languageDetector";
import Footer from '../Components/Footer';
const lang = languageDetector();
var w = window.innerWidth;

class Home extends Component {
    constructor(props) {
        super(props);
        const {lang} = this.props;
        this.state = {
          propertyStyle: 0,
          propertytypes: [],
          scrollStyle: {},
          locations: lang=="en"?locationData.locations: locationData.locations_ar,
          selectedLocations: [],
          subLocationSend: [],
          passPurpose: 1,
          selectedPurpose: true,
          passType: lang == "en" ? "properties" : "عقارات",
          pricefrom: 0,
          priceto: 1000000000,
          bedroom: -1,
          bathroom: 0,
          frequency: 0,
          locForUrl: lang == "en" ? "uae" : "الامارات",
          furnishedornot: 0,
          urlPurpose: "for-rent",
          urlPropertyType: "properties",
          urlLocation: "uae",
          urlbedroom: "",
          passTypeNameSelected: lang == "en" ? "Properties" : "عقارات",
          passDisplayType: 0,
          passLocationTypeId: 0,
          passUrlForSeo: "for-rent/properties/uae",
          showDownloadLink: true
        };
        this.propertyPurposeChangleHandle = this.propertyPurposeChangleHandle.bind(this);
      }
    
      HandlePropertyStyle = (id, e) => {
        e.preventDefault();
        this.setState({ propertyStyle: id });
      };
      propertyPurposeChangleHandle = (e) => {
        this.setState({
          passPurpose: e.target.value,
          urlPurpose: e.target.value == 1 ? "for-rent" : e.target.value == 2 ? "for-sale" : "for-sharing",
        });
        // var bedroom = $("#bedroom").find(":selected").attr('name')===undefined?"":$("#bedroom").find(":selected").attr('name')
        // const urlPurpose = {
        //   purposeUrl:  $("#propertyPurpose").find(":selegcted").attr('name').toLowerCase().replace(/\s+/g, '-')+"/",
        //   propertyTypeUrl: $("#propertyType").find(":selected").attr('name')=="properties"? "properties/": bedroom + $("#propertyType").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-')+"/",
        //   subLocationSend: this.state.subLocationSend
        // };

        // const data = { lang: lang, id: e.target.value }
        // API.post("propertytypeByPurposeTwo", data)
        //   .then((response) => {
        //     //  //console.log(response.data)
        //     this.setState({
        //       isLoading: false,
        //       propertytypes: response.data.propertytypes,
        //     });
        //   })
        //   .catch();
      };
      isWebView = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return userAgent.includes('wv') || userAgent.includes('WebView');
      };
      propertyTypeOnChangeHadnler = (e) => {
        var selectElement = document.getElementById('propertyTypeSelect');
        var selectedOption = selectElement.options[selectElement.selectedIndex];
        var dataTypeForUrl = selectedOption.getAttribute('data-type-for-url');

        // console.log(dataTypeForUrl); // Output the value to the console

        this.setState({
          passType: $("#propertyTypeSelect").find(":selected").attr('name'),
          furnishedornot: e.target.selectedOptions[0].getAttribute('furnishedornot'),
          urlPropertyType: dataTypeForUrl,
          passTypeNameSelected: $("#propertyTypeSelect").find(":selected").attr('typeSelected'),
    
        });
      };
      priceFromHandle = (e) => {
        //console.log("price from", e.target.value);
        this.setState({
          pricefrom: e.target.value,
        });
      };
      priceToHandle = (e) => {
        this.setState({
          priceto: e.target.value,
        });
      };
      frequencyHandle = (e) => {
        this.setState({
          frequency: e.target.value,
        });
      };
      bedRoomOnChangeHandle = (e) => {
        this.setState({
          bedroom: e.target.value,
          urlbedroom: e.target.value == 0 ? "studio" + "-" : e.target.value + "-" + "bedroom" + "-",
        });
      }
      bathRoomOnChangeHandle = (e) => {
        this.setState({
          bathroom: e.target.value,
        });
    }

    getContent = (lang) => {
        if (lang === 'ar') {
            return {
                title: "البحث عن العقارات - أكبر بوابة عقارات للإيجار والبيع في الإمارات العربية المتحدة",
                description: 'ابحث عن عقارات للإيجار والبيع في دبي والشارقة وعجمان وأبو ظبي وجميع الإمارات العربية المتحدة | استكشف أكثر من 1000 شقة ، فيلا ، تاون هاوس ، مكتب ، أرض ، معسكر عمل.',
                keywords: "ابحث عن العقارات، ابحث عن العقارات في عجمان، ابحث عن العقارات في الإمارات العربية المتحدة، ابحث عن العقارات في دبي، ابحث عن العقارات في الشارقة، موقع العقارات في الإمارات العربية المتحدة، بوابة العقارات في الإمارات العربية المتحدة، ابحث عن العقارات، العقارات، الشقق، الشقق، الفيلا، إيجار العقارات، بيع العقارات ، مشاركة الممتلكات، والإقامة، والشقق الرخيصة، والشقق الميزانية، شقة الميزانية",
                language: "https://www.findproperties.ae/ar/"
            };
        } else {
            return {
                title: "Find Properties - UAE's largest property portal for rent and sale",
                description: 'Find properties for rent and sale in Dubai, Sharjah, Ajman, Abu Dhabi, and all UAE | Explore 1000+ Flats, villas, townhouse, office, land, labor camp.',
                keywords: "Find Properties, Find properties in Ajman, Find properties in UAE, Find properties in Dubai, Find properties in Sharjah, Property website in UAE, Property portal in UAE, findproperties, property, properties, flats, apartments, villa, rent property, sale property, share property, accomodation, cheap flats, budget flats, budget apartment",
                language: "https://www.findproperties.ae/"
            };
        }
    };
    componentDidMount() {
      if (this.isWebView()) {
          this.setState({ showDownloadLink: false });
      }
    }
    render() {
      const { showDownloadLink } = this.state;
        // const lang = localStorage.getItem("lang") || "en";
        // const { currentLanguage } = this.props;
        // const lang = languageDetector();
        // const language= lang =="en"?"":"ar";
        const currentPath = window.location.pathname;
        // console.log("path anem", currentPath)        
        const langCur = lang == "ar" ? "ar/" : ""

        const structuredData = {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": {
            "@language": "en",
            "@value": "Find Properties"
          },
          "alternateName": {
            "@language": "ar",
            "@value": "ابحث عن العقارات"
          },
          "url": "https://www.findproperties.ae",
          "potentialAction": [
            {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": `https://www.findproperties.ae/${langCur}{purpose}/{propertytype}/{location}`,
                "inLanguage": "lang"
              },
              "query-input": "required name=purpose required name=propertytype required name=location"
            }
          ],
          "inLanguage": ["en", "ar"],
          "mainEntityOfPage": [
            {
              "@type": "WebPage",
              "@id": "https://www.findproperties.ae",
              "url": "https://www.findproperties.ae",
              "inLanguage": "en",
              "name": "Find Properties in UAE"
            },
            {
              "@type": "WebPage",
              "@id": "https://www.findproperties.ae/ar",
              "url": "https://www.findproperties.ae/ar",
              "inLanguage": "ar",
              "name": "ابحث عن العقارات"
            }
          ]
        };
        // const structuredData = {
        //   "@context": "https://schema.org",
        //   "@type": "WebSite",
        //   "name": {
        //     "@language": "en",
        //     "@value": "Find Properties"
        //   },
        //   "alternateName": {
        //     "@language": "ar",
        //     "@value": "ابحث عن العقارات"
        //   },
        //   "url": "https://www.findproperties.ae",
        //   "potentialAction": [
        //     {
        //       "@type": "SearchAction",
        //       "target": {
        //         "@type": "EntryPoint",
        //         "urlTemplate": "https://www.findproperties.ae/{purpose}/{propertytype}/{emirate}",
        //         "inLanguage": "en"
        //       },
        //       "query-input": "required name=purpose required name=propertytype required name=location"
        //     },
        //     {
        //       "@type": "SearchAction",
        //       "target": {
        //         "@type": "EntryPoint",
        //         "urlTemplate": "https://www.findproperties.ae/ar/{purpose}/{propertytype}/{location}",
        //         "inLanguage": "ar"
        //       },
        //       "query-input": "required name=purpose required name=propertytype required name=location"
        //     }
        //   ],
        //   "inLanguage": ["en", "ar"],
        //   "alternateName": [
        //     {
        //       "@language": "en",
        //       "@value": "Find Properties"
        //     },
        //     {
        //       "@language": "ar",
        //       "@value": "ابحث عن العقارات"
        //     }
        //   ],
        //   "mainEntityOfPage": [
        //     {
        //       "@type": "WebPage",
        //       "@id": "https://www.findproperties.ae",
        //       "url": "https://www.findproperties.ae",
        //       "inLanguage": "en",
        //       "name": "Find Properties in UAE"
        //     },
        //     {
        //       "@type": "WebPage",
        //       "@id": "https://www.findproperties.ae/ar",
        //       "url": "https://www.findproperties.ae/ar",
        //       "inLanguage": "ar",
        //       "name": "ابحث عن العقارات"
        //     }
        //   ]
        // };       

        // console.log("curr lan hom", this.props)
        // console.log("Current lang:", lang); // Add this line to log the lang prop
        // const {lang} =this.props;
        const content = this.getContent(lang);
        const language = lang == "ar" ? "/ar" : ""
        return (
            <div dir={lang == "en" ? 'ltr' : 'rtl'}>
                <Helmet>
                    <title>{content.title} </title>
                    <meta name="description" content={content.description} />
                    <meta itemprop="name" content={content.title} />
                    <meta itemprop="description" content={content.description}></meta>
                    <meta itemprop="image" content="../../../frontendUI/img/logo.png" />

                    <meta name="keywords" content={content.keywords} />
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta itemProp="image" content="https://findproperties.ae/frontendUI/img/logo.png" />
                    <meta property="og:url" content="https://findproperties.ae" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={content.title} />
                    <meta property="og:description" content={content.description} />
                    <meta property="og:image" content="https://findproperties.ae/frontendUI/img/logo.png" />
                    <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={content.title} />
                    <meta name="twitter:description" content={content.description} />
                    {lang == "en" ? <link rel="canonical" href={"https://findproperties.ae"} /> : <link rel="canonical" href={"https://findproperties.ae/ar/"} />}
                    <link rel="alternate" hreflang="en" href={"https://findproperties.ae"} />
                    <link rel="alternate" hreflang="ar" href={"https://findproperties.ae/ar/"} />

                    <meta name="twitter:image" content="https://findproperties.ae/frontendUI/img/logo.png" />
                    <meta name="robots" content="index, follow" />
                    {/* {(currentPath === '/' || currentPath==='/ar') && (
                      <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                      </script>
                     )}  */}
                </Helmet>

                {/* <BannerComponent /> */}

        <div className="banner banner-1 banner-3 dark-overlay bg-cover bg-center">
          <div className="container">
            <div className="banner-item">
              <div className="banner-inner">
                <div className="banner-text">
                  <h1 lang={lang == "en" ? "en" : "ar"} className="title">
                    {i18next.t("findPropertieslogo")}
                    {/* {title} */}
                    
                  </h1>
                  <p lang={lang == "en" ? "en" : "ar"} className="subtitle">
                    {i18next.t("uaelargestportal")}
                  </p>
                </div>
                <div className="radio-inputs" onChange={this.propertyPurposeChangleHandle}>
                    <label className="radio">
                      <input type="radio" name="radio"  value={1} defaultChecked />
                      <span className="name"><span> {i18next.t("rent")}</span></span>
                    </label>
                    <label className="radio">
                      <input type="radio" name="radio"   value={2}/>
                      <span className="name"> {i18next.t("buy")}</span>
                    </label>
                        
                    <label className="radio">
                      <input type="radio" name="radio"   value={3} />
                      <span className="name">{i18next.t("sharing")}</span>
                    </label>
                  </div>
                <div className="acr-filter-form">
                  <form method="post">
                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <Autocomplete
                          multiple
                          limitTags={3}
                          id="multiple-limit-tags"
                          options={this.state.locations}
                          onChange={(event, newValue) => {
                            // console.log("home page ac change", newValue)
                            this.setState({
                              selectedLocations: newValue,
                              subLocationSend: newValue.length > 0 ? newValue[0] : [],
                              urlLocation: newValue == undefined || newValue.length == 0 ? "uae" : newValue[0].slug_en,
                              locForUrl: newValue.length > 0 ? newValue[0].location : [],
                              passLocationTypeId:  newValue.length > 0 ?newValue[0]: [],
                            });
                            var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('slug_en')
                            const urlPurpose = {
                              purpose: $("#propertyPurpose").find(":selected").attr('slug_en'),
                              propertyType: "/" + $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + "/" + this.state.propertyType + "/",
                              subLocationSend: newValue.length > 0 ? newValue[0] : []
                            };
                            // this.propertyUrl(urlPurpose)

                          }}
                          getOptionLabel={(option) => option.location}
                          value={this.state.selectedLocations}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label=""
                              placeholder={lang == 'en' ? "Location/Emirate/city/street/building" : "الموقع/اماره/مدينه/شارع/مبنى"}
                            />
                          )}

                        />
                      </div>
                      <div id="propertyType" className="col-lg-4 col-md-4">
                           <div className="form-group">
                           <select className="form-group" id="propertyTypeSelect"
                            onChange={this.propertyTypeOnChangeHadnler}>
                             {lang=="en" ?<>
                               <option name="properties" typeselected="Property type" data-type-for-url="properties" data-furnished="0" bedroombath="0" value="0">Property type</option>
                                <option name="apartments" typeselected="Apartment" data-type-for-url="apartments" data-furnished="1" bedroombath="1" value="1">Apartment</option>
                                <option name="residential-villa" typeselected="Villa Residential" data-type-for-url="residential-villa" data-furnished="1" bedroombath="1" value="20">Villa Residential</option>
                                {this.state.passPurpose ==1 || this.state.passPurpose ==2 ?
                                <>
                                <option name="residential-land" typeselected="Land Residential" data-type-for-url="residential-land" data-furnished="0" bedroombath="0" value="11">Land Residential</option>
                                <option name="commerical-land" typeselected="Land Commercial" data-type-for-url="commerical-land" data-furnished="0" bedroombath="0" value="9">Land Commercial</option>
                                <option name="industrial-land" typeselected="Land Industrial" data-type-for-url="industrial-land" data-furnished="0" bedroombath="0" value="10">Land Industrial</option>
                                <option name="office" typeselected="Office" data-type-for-url="office" data-furnished="1" bedroombath="0" value="12">Office</option>
                                <option name="shops" typeselected="Shop" data-type-for-url="shops" data-furnished="0" bedroombath="0" value="15">Shop</option>
                                <option name="commercial-villa" typeselected="Villa Commercial" data-type-for-url="commercial-villa" data-furnished="1" bedroombath="1" value="18">Villa Commercial</option>
                                <option name="buildings" typeselected="Building" data-type-for-url="buildings" data-furnished="0" bedroombath="0" value="2">Building</option>
                                <option name="warehouse" typeselected="Warehouse" data-type-for-url="warehouse" data-furnished="0" bedroombath="0" value="21">Warehouse</option>
                                <option name="showroom" typeselected="Showroom" data-type-for-url="showroom" data-furnished="0" bedroombath="0" value="16">Showroom</option>
                                <option name="compound-villa" typeselected="Villa Compound" data-type-for-url="compound-villa" data-furnished="1" bedroombath="1" value="19">Villa Compound</option>
                                <option name="labour-camps" typeselected="Labor Camp" data-type-for-url="labour-camps" data-furnished="0" bedroombath="1" value="8">Labor Camp</option>
                                <option name="townhouse" typeselected="Townhouse" data-type-for-url="townhouse" data-furnished="1" bedroombath="1" value="17">Townhouse</option>
                                <option name="hotel-apartments" typeselected="Hotel Apartment" data-type-for-url="hotel-apartments" data-furnished="0" bedroombath="1" value="7">Hotel Apartment</option>
                                <option name="floor" typeselected="Floor" data-type-for-url="floor" data-furnished="0" bedroombath="0" value="6">Floor</option>
                                <option name="farm" typeselected="Farm" data-type-for-url="farm" data-furnished="0" bedroombath="0" value="5">Farm</option>
                                <option name="factories" typeselected="Factory" data-type-for-url="factories" data-furnished="0" bedroombath="0" value="4">Factory</option>
                                <option name="bulk-units" typeselected="Bulk Unit" data-type-for-url="bulk-units" data-furnished="0" bedroombath="0" value="3">Bulk Unit</option>
                                <option name="other-commercial" typeselected="Other Commercial" data-type-for-url="other-commercial" data-furnished="0" bedroombath="0" value="13">Other Commercial</option>
                                <option name="penthouse" typeselected="Penthouse" data-type-for-url="penthouse" data-furnished="1" bedroombath="1" value="14">Penthouse</option>
                                </>
                                :null}
                              </>:   <>
                                <option name="عقارات" typeselected="نوع العقار" data-type-for-url="properties" data-furnished="0" bedroombath="0" value="0">نوع العقار</option>
                                <option name="شقة" typeselected="شقة" data-type-for-url="apartments" data-furnished="1" bedroombath="1" value="1">شقة</option>
                                <option name="فيلا-سكني" typeselected="فيلا سكني" data-type-for-url="residential-villa" data-furnished="1" bedroombath="1" value="20">فيلا سكني</option>
                                {this.state.passPurpose ==1 || this.state.passPurpose ==2 ?
                                <>
                                <option name="أرض-سكنية" typeselected="أرض سكنية" data-type-for-url="residential-land" data-furnished="0" bedroombath="0" value="11">أرض سكنية</option>
                                <option name="أرض-تجارية" typeselected="أرض تجارية" data-type-for-url="commerical-land" data-furnished="0" bedroombath="0" value="9">أرض تجارية</option>
                                <option name="أرض-صناعية" typeselected="أرض صناعية" data-type-for-url="industrial-land" data-furnished="0" bedroombath="0" value="10">أرض صناعية</option>
                                <option name="مكتب" typeselected="مكتب" data-type-for-url="office" data-furnished="1" bedroombath="0" value="12">مكتب</option>
                                <option name="محل
                                    " typeselected="محل" data-type-for-url="shops" data-furnished="0" bedroombath="0" value="15">محل</option>
                                <option name="فيلا-تجارية" typeselected="فيلا تجارية" data-type-for-url="commercial-villa" data-furnished="1" bedroombath="1" value="18">فيلا تجارية</option>
                                <option name="مبنى " typeselected="مبنى " data-type-for-url="buildings" data-furnished="0" bedroombath="0" value="2">مبنى </option>
                                <option name="مستودع" typeselected="مستودع" data-type-for-url="warehouse" data-furnished="0" bedroombath="0" value="21">مستودع</option>
                                <option name="صالة-عرض" typeselected="صالة عرض" data-type-for-url="showroom" data-furnished="0" bedroombath="0" value="16">صالة عرض</option>
                                <option name="فيلا-مجمع-فلل " typeselected="فيلا  مجمع فلل " data-type-for-url="compound-villa" data-furnished="1" bedroombath="1" value="19">فيلا  مجمع فلل </option>
                                <option name="سكن-عمال" typeselected="سكن عمال" data-type-for-url="labour-camps" data-furnished="0" bedroombath="1" value="8">سكن عمال</option>
                                <option name="تاون-هاوس" typeselected="تاون هاوس" data-type-for-url="townhouse" data-furnished="1" bedroombath="1" value="17">تاون هاوس</option>
                                <option name="شقة-فندقية" typeselected="شقة فندقية" data-type-for-url="hotel-apartments" data-furnished="0" bedroombath="1" value="7">شقة فندقية</option>
                                <option name="طابق-سكني" typeselected="طابق سكني" data-type-for-url="floor" data-furnished="0" bedroombath="0" value="6">طابق سكني</option>
                                <option name="مزرعة
                                    " typeselected="مزرعة" data-type-for-url="farm" data-furnished="0" bedroombath="0" value="5">مزرعة</option>
                                <option name="مصنع" typeselected="مصنع" data-type-for-url="factories" data-furnished="0" bedroombath="0" value="4">مصنع</option>
                                <option name="عقارات-مجمعه" typeselected="عقارات مجمعه" data-type-for-url="bulk-units" data-furnished="0" bedroombath="0" value="3">عقارات مجمعه</option>
                                <option name="عقارات-تجارية-أخرى" typeselected="عقارات تجارية أخرى" data-type-for-url="other-commercial" data-furnished="0" bedroombath="0" value="13">عقارات تجارية أخرى</option>
                                <option name="بنتهاوس" typeselected="بنتهاوس" data-type-for-url="penthouse" data-furnished="1" bedroombath="1" value="14">بنتهاوس</option>
                                </>
                                :null}
                                </>
                              }
                               </select>
                          </div>
                       </div>     
                       <div className="col-lg-2 col-md-2 d-sm-none d-md-block d-lg-block d-none">
                          <div className="form-group">
                            <select id="bedroom" className="form-group" onChange={this.bedRoomOnChangeHandle}>
                              <option value="-1" selected>{i18next.t("bedroom")}</option>
                              <option value="0">{i18next.t("studiobed")}</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="20+">20+</option>
                            </select>
                          </div>
                        </div>
                      <div className="col-lg-2 col-md-2 d-sm-none d-md-block d-lg-block d-none">
                          <div className="form-group">
                            <select id="bathroom" className="form-group" name="baths" onChange={this.bathRoomOnChangeHandle}>
                              <option selected value="*">
                                {i18next.t("bathroom")}
                              </option>
                              <option val="1">1</option>
                              <option val="2">2</option>
                              <option val="3">3</option>
                              <option val="4">4</option>
                              <option val="5">5</option>
                              <option val="6">6</option>
                              <option val="7">7</option>
                              <option val="8">8</option>
                              <option val="9">9</option>
                              <option val="10">10</option>
                              <option val="11">11</option>
                              <option val="12">12</option>
                              <option val="13">13</option>
                              <option val="14">14</option>
                              <option val="15">15</option>
                              <option val="16">16</option>
                              <option val="17">17</option>
                              <option val="18">18</option>
                              <option val="19">19</option>
                              <option val="20">20</option>
                              <option val="20+">20+</option>
                            </select>
                          </div>
                        </div>                  
                      <div className="col-lg-2 col-md-2  d-sm-none d-md-block d-lg-block d-none">
                          <div className="form-group">
                            <input
                              onChange={this.priceFromHandle}
                              className="form-group"
                              type="number"
                              placeholder={i18next.t("from")}
                            ></input>
                          </div>
                        </div>                    
                      <div className="col-lg-2 col-md-2 d-sm-none d-md-block d-none ">
                          <div className="form-group">
                            <input
                              onChange={this.priceToHandle}
                              className="form-group col-lg-2"
                              type="number"
                              placeholder={i18next.t("to")}
                            ></input>
                          </div>
                        </div>
                      
                        <div className="col-lg-2 col-md-2 d-sm-none d-md-block d-lg-block d-none">
                            <div className="form-group">
                              <select id="frequencyview"
                                className='filter-input mb-3'
                                onChange={this.frequencyOnChangeHandler}
                                name=''
                              // id=''
                              >
                                <option selected value='0' id='frequency'>
                                  {i18next.t("per")}
                                </option>
                                <option value='4'>{i18next.t("daily")}</option>
                                <option value='3'>{i18next.t("weekly")}</option>
                                <option value='2'>{i18next.t("monthly")}</option>
                                <option value='1'>{i18next.t("yearly")}</option>
                              </select>
                            </div>
                       </div> 
                       {w>900 &&
                       <>
                        <div id="searchProperty" className="submit-btn col">
                          <div className="form-group">
                            <a href={language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation                                                }
                              type="submit"
                              className="btn-custom secondary btn-block"
                              name="button">
                              {/* <FaSearch />&nbsp; */}
                              <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><g/><g stroke-linecap="round" stroke-linejoin="round"/><path d="M15.796 15.811 21 21m-3-10.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0" stroke="#519fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
                              {" "}
                              {i18next.t("findProperties")}
                            </a>
                          </div>
                        </div>
                        <div id="searchPropertyOnMap" className="submit-btn col">
                          <div className="form-group">
                            {/* <a
                               type="submit"
                              className="btn-custom secondary btn-block"
                              name="button"
                              href={language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation }
                            >
                              <svg className="list-icons">
                                  <use href="/sprite.svg#map"></use>
                              </svg>
                              {" "}
                              {i18next.t("findonmap")}{" "}
                            </a> */}
                            <Link
                               type="submit"
                              className="btn-custom secondary btn-block"
                              name="button"
                              //   to={{
                              to={{
                                // pathname: language+"/"+this.state.urlPurpose+"/"+this.state.urlbedroom+this.state.urlPropertyType+"/"+this.state.urlLocation,
                                pathname: language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation,
                                state: {
                                  passDisplayType: 2,
                                  passLocation: this.state.selectedLocations,
                                  passsubLocationSend: this.state.subLocationSend,
                                  passPurpose: this.state.passPurpose,
                                  passType: this.state.passType,
                                  passBed: this.state.bedroom,
                                  passBath: this.state.bathroom,
                                  passPricefrom: this.state.pricefrom,
                                  passPriceto: this.state.priceto,
                                  passFrequency: this.state.frequency,
                                  furnishedornot: this.state.furnishedornot,
                                  locForUrl: this.state.locForUrl,
                                  passPropTypeName: this.state.passTypeNameSelected,
                                  passLocationTypeId: this.state.passLocationTypeId,
                                  purposeForUrl: this.state.urlPurpose,
                                  typeForUrl:this.state.urlPropertyType,                                }
                              }}
                            >
                              {/* <FaMap/> &nbsp; */}
                              {/* <svg className="list-icons">
                                  <use href="/sprite.svg#map"></use>
                              </svg> */}
                              <svg width="30" height="30"  fill="#519fff" viewBox="-1.5 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M15.084 15.2H.916a.264.264 0 0 1-.254-.42l2.36-4.492a.87.87 0 0 1 .696-.42h.827a9.5 9.5 0 0 0 .943 1.108H3.912l-1.637 3.116h11.45l-1.637-3.116h-1.34a9.5 9.5 0 0 0 .943-1.109h.591a.87.87 0 0 1 .696.421l2.36 4.492a.264.264 0 0 1-.254.42M11.4 7.189c0 2.64-2.176 2.888-3.103 5.46a.182.182 0 0 1-.356 0c-.928-2.572-3.104-2.82-3.104-5.46a3.282 3.282 0 0 1 6.563 0m-1.86-.005a1.425 1.425 0 1 0-1.425 1.425A1.425 1.425 0 0 0 9.54 7.184"/></svg>                             
                              {" "}
                              {i18next.t("findonmap")}{" "}
                            </Link>
                          </div>
                        </div>
                        </>
                      }
                  </div>
                  {w<900 &&
                  <div className="row flex">
                      <div id="searchProperty" className="submit-btn col">
                          <div className="form-group">
                            <a href={language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation }                           
                              type="submit"
                              className="btn-custom secondary btn-block"
                              name="button">
                              {/* <FaSearch />&nbsp; */}
                              <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><g/><g stroke-linecap="round" stroke-linejoin="round"/><path d="M15.796 15.811 21 21m-3-10.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0" stroke="#519fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
                              {" "}
                              {i18next.t("findProperties")}
                            </a>
                          </div>
                        </div>
                      
                      <div id="searchPropertyOnMap" className="submit-btn col">
                          <div className="form-group">
                            {/* <a
                               type="submit"
                              className="btn-custom secondary btn-block"
                              name="button" href={language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation}
                            >
                              <svg width="40" height="30"  fill= "#519fff" viewBox="-1.5 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M15.084 15.2H.916a.264.264 0 0 1-.254-.42l2.36-4.492a.87.87 0 0 1 .696-.42h.827a9.5 9.5 0 0 0 .943 1.108H3.912l-1.637 3.116h11.45l-1.637-3.116h-1.34a9.5 9.5 0 0 0 .943-1.109h.591a.87.87 0 0 1 .696.421l2.36 4.492a.264.264 0 0 1-.254.42M11.4 7.189c0 2.64-2.176 2.888-3.103 5.46a.182.182 0 0 1-.356 0c-.928-2.572-3.104-2.82-3.104-5.46a3.282 3.282 0 0 1 6.563 0m-1.86-.005a1.425 1.425 0 1 0-1.425 1.425A1.425 1.425 0 0 0 9.54 7.184"/></svg>
                                {i18next.t("findonmap")}{" "}
                            </a> */}
                                                        <Link
                               type="submit"
                              className="btn-custom secondary btn-block"
                              name="button"
                              //   to={{
                              to={{
                                // pathname: language+"/"+this.state.urlPurpose+"/"+this.state.urlbedroom+this.state.urlPropertyType+"/"+this.state.urlLocation,
                                pathname: language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation,
                                state: {
                                  passDisplayType: 2,
                                  passLocation: this.state.selectedLocations,
                                  passsubLocationSend: this.state.subLocationSend,
                                  passPurpose: this.state.passPurpose,
                                  passType: this.state.passType,
                                  passBed: this.state.bedroom,
                                  passBath: this.state.bathroom,
                                  passPricefrom: this.state.pricefrom,
                                  passPriceto: this.state.priceto,
                                  passFrequency: this.state.frequency,
                                  furnishedornot: this.state.furnishedornot,
                                  locForUrl: this.state.locForUrl,
                                  passPropTypeName: this.state.passTypeNameSelected,
                                  passLocationTypeId: this.state.passLocationTypeId,
                                  purposeForUrl: this.state.urlPurpose,
                                  typeForUrl:this.state.urlPropertyType,                                }
                              }}
                            >
                              {/* <FaMap/> &nbsp; */}
                              {/* <svg className="list-icons">
                                  <use href="/sprite.svg#map"></use>
                              </svg> */}
                              <svg width="30" height="30"  fill="#519fff" viewBox="-1.5 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M15.084 15.2H.916a.264.264 0 0 1-.254-.42l2.36-4.492a.87.87 0 0 1 .696-.42h.827a9.5 9.5 0 0 0 .943 1.108H3.912l-1.637 3.116h11.45l-1.637-3.116h-1.34a9.5 9.5 0 0 0 .943-1.109h.591a.87.87 0 0 1 .696.421l2.36 4.492a.264.264 0 0 1-.254.42M11.4 7.189c0 2.64-2.176 2.888-3.103 5.46a.182.182 0 0 1-.356 0c-.928-2.572-3.104-2.82-3.104-5.46a3.282 3.282 0 0 1 6.563 0m-1.86-.005a1.425 1.425 0 1 0-1.425 1.425A1.425 1.425 0 0 0 9.54 7.184"/></svg>                             
                              {" "}
                              {i18next.t("findonmap")}{" "}
                            </Link>
                          </div>
                        </div>           
                  
                  </div>
                  }

                    <div className="advanced-search2">
                      <div className="row">  
                      </div>
                    </div>

                  </form>
                  {/* <div className="advanced-search-trigger semi-circle active">
                  <i className="fas fa-chevron-down" />
                </div> */}
                </div>
              </div>
              <div class="cloud-overlay"></div>
            </div>
          </div>
        </div>
                {/* Banner End */}

                {/* <Suspense fallback=
                    {<div class='lds-ripple center'>
                        <div></div>
                        <div></div>
                    </div>
                    }> */}
                    {/* <RecentListing />  */}
                    {/* <BrowseByCityComponent /> */}
                    <CategoriesHomeComponet />
                    {showDownloadLink && (
                        <DownloadOurApp />
                    )}
                {/* </Suspense> */}
                {lang == "en" ? <HomePageBottomEn /> : <HomePageBottomAr />}
                <Footer />
            </div>
        )
    }
}
  
  export default Home;
  