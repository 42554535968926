import React from 'react'

function Footer() {
    return (
        <div className="footer">
            {/* <ul className="social-media">
                <li> <a target="_blank" href="https://www.facebook.com/findpropertiesuae"> <i className="fa fa-facebook-f" /> </a> </li>
                <li> <a target="_blank" href="https://www.instagram.com/findpropertiesofficial/"> <i className="fa fa-instagram" /> </a> </li>
            </ul> */}
            <div className="copyright">
                <div className="row socials-footer">
                    <div className="col">
                        <a target="_blank" href="https://www.facebook.com/findpropertiesuae"> 
                            <svg id="facebook"  width="30" height="30" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <title>facebook_line</title>
                                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                                    <g id="Brand" transform="translate(-240.000000, -0.000000)">
                                        <g id="facebook_line" transform="translate(240.000000, 0.000000)">
                                            <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fillRule="nonzero"></path>
                                            <path d="M4,12 C4,7.58172 7.58172,4 12,4 C16.4183,4 20,7.58172 20,12 C20,16.0796 16.9463,19.446 13,19.9381 L13,14 L15,14 C15.5523,14 16,13.5523 16,13 C16,12.4477 15.5523,12 15,12 L13,12 L13,10 C13,9.44772 13.4477,9 14,9 L14.5,9 C15.0523,9 15.5,8.55228 15.5,8 C15.5,7.44772 15.0523,7 14.5,7 L14,7 C12.3431,7 11,8.34315 11,10 L11,12 L9,12 C8.44772,12 8,12.4477 8,13 C8,13.5523 8.44772,14 9,14 L11,14 L11,19.9381 C7.05369,19.446 4,16.0796 4,12 Z M12,22 C17.5228,22 22,17.5228 22,12 C22,6.47715 17.5228,2 12,2 C6.47715,2 2,6.47715 2,12 C2,17.5228 6.47715,22 12,22 Z" id="形状" fill="#09244B"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div className="col">
                        <a target="_blank" href="https://www.instagram.com/findpropertiesofficial/">
                             <svg fill="#c13584" width="30" height="30" viewBox="-2 -2 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-instagram"><path d='M14.017 0h-8.07A5.954 5.954 0 0 0 0 5.948v8.07a5.954 5.954 0 0 0 5.948 5.947h8.07a5.954 5.954 0 0 0 5.947-5.948v-8.07A5.954 5.954 0 0 0 14.017 0zm3.94 14.017a3.94 3.94 0 0 1-3.94 3.94h-8.07a3.94 3.94 0 0 1-3.939-3.94v-8.07a3.94 3.94 0 0 1 3.94-3.939h8.07a3.94 3.94 0 0 1 3.939 3.94v8.07z' /><path d='M9.982 4.819A5.17 5.17 0 0 0 4.82 9.982a5.17 5.17 0 0 0 5.163 5.164 5.17 5.17 0 0 0 5.164-5.164A5.17 5.17 0 0 0 9.982 4.82zm0 8.319a3.155 3.155 0 1 1 0-6.31 3.155 3.155 0 0 1 0 6.31z' /><circle cx='15.156' cy='4.858' r='1.237' /></svg>
                        </a>
                    </div>
                    <div className="col">
                        <a target="_blank" href="https://www.youtube.com/@findproperties3771">
                            <svg fill="red" width="30" height="30" viewBox="-2 -2 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-youtube-circle"><path d='M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z' /><path d='M12.923 6.526H7.077C5.93 6.526 5 7.446 5 8.58v2.89c0 1.135.93 2.054 2.077 2.054h5.846c1.147 0 2.077-.92 2.077-2.054V8.58c0-1.135-.93-2.054-2.077-2.054zm-1.404 3.64l-2.735 1.29a.11.11 0 0 1-.157-.099v-2.66a.11.11 0 0 1 .16-.097l2.734 1.37c.081.042.08.157-.002.196z' /></svg>
                        </a>
                    </div>

                </div>
                <p>Copyright © Designed &amp; Developed by <a href="https://findproperties.ae/" target="_blank">FindProperties</a> 2024</p>
            </div>

        </div>
    )
}
export default Footer
