import React, { Component } from "react";
import { Nav, Tab } from 'react-bootstrap';
import i18next from 'i18next';

export default class HomePageBottomEn extends Component {
  render() {
    const lang = "en";
    return (
      <footer className="acr-footer footer-dark">
        <div className="container">
          <Tab.Container defaultActiveKey="#pills-abu" id="locations-tab">
            <Nav variant="pills" className="mb-3" role="tablist">
              <Nav.Item>
                <Nav.Link eventKey="#pills-abu">{i18next.t("abudhabi")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="#pills-dub">{i18next.t("dubai")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="#pills-shr">{i18next.t("sharjah")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="#pills-ajm">{i18next.t("ajman")}</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="#pills-umq">{i18next.t("umalq")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="#pills-rak">{i18next.t("rak")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="#pills-fuj">{i18next.t("fuj")}</Nav.Link>
              </Nav.Item> */}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="#pills-abu">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-4">
                    <b>{i18next.t("popularsearches")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/properties/abu-dhabi">Properties for rent in Abu Dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi">Apartments for rent in Abu Dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/abu-dhabi">Studio Apartments for rent in Abu Dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi"> Furnished Apartments for rent in Abu Dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi"> Flats for rent in Abu Dhabi</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/hamdan-street"> Apartments for rent in Hamdan street</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/mohammed-bin-zayed-city"> Apartments for rent in Mohamed Bin zayed city</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/al-reem-island"> Apartments for rent in Al Reem Island</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/mussafah"> Apartments for rent in Mussafah</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/corniche-area"> Apartments for rent in Corniche area</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/al-bateen"> Apartments for rent in Al Bateen</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/mussafah/shabiya"> Apartments for rent in Shabiya</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/al-muroor"> Apartments for rent in Muroor</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/abu-dhabi/tourist-club-area-tca"> Apartments for rent in Tourist club area TCA</a></li>
                      <li><a href="https://findproperties.ae/for-rent/residential-villa/abu-dhabi"> villa for rent in abu dhabi</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/shops/abu-dhabi">Shops for rent in Abu Dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-rent/office/abu-dhabi">Office for rent in Abu dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-sale/properties/abu-dhabi">Properties for sale in Abu Dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-sale/apartments/abu-dhabi">Apartments for sale in Abu Dhabi</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-villa/abu-dhabi">Villa for sale in Abu Dhabi</a></li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#pills-dub">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-4">
                    <b>{i18next.t("popularsearches")}</b>
                    <ul>
                     <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#pills-shr">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-4">
                    <b>{i18next.t("popularsearches")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/apartments/sharjah">Apartments for rent in Sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-rent/residential-villa/sharjah">villas for rent in sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-villa/sharjah">Villa for sale in Sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-sale/apartments/sharjah">Apartments for sale in sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/sharjah">studio for rent in sharjah</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/rolla-area">Apartments for rent in Rolla area</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/al-majaz">Apartments for rent in Al Majaz</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/muwailih-commercial">Apartments for rent in Muwaileh commercial</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/sharjah/al-qasimia">Apartments for rent in Qasimiya</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-villa/sharjah/al-azra">Villas for sale in Al Azra</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-sale/properties/sharjah">Properties for sale in sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-land/sharjah/hoshi">Residential land for sale in sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-sale/properties/sharjah/hoshi">properties for sale in Hoshi</a></li>
                      <li><a href="https://findproperties.ae/for-rent/warehouse/sharjah/al-saja">Warehouses for rent in al saja</a></li>
                      <li><a href="https://findproperties.ae/for-rent/commerical-land/sharjah">Commercial land for rent in sharjah</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/properties/sharjah">Properties for rent in sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-rent/properties/sharjah/abu-shagara">Properties for rent in Abu Shagara</a></li>
                      <li><a href="https://findproperties.ae/for-rent/office/sharjah">Office for rent in sharjah</a></li>
                      <li><a href="https://findproperties.ae/for-rent/properties/sharjah/industrial-area">Properties for rent in sharjah Industrial area</a></li>
                      <li><a href="https://findproperties.ae/for-rent/properties/sharjah/emirates-industrial-city">Properties for rent in Emirates Industrial city</a></li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#pills-ajm">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-4">
                    <b>{i18next.t("popularsearches")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/properties/ajman">Properties for rent in Ajman</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/ajman">Apartments for rent in Ajman</a></li>
                      <li><a href="https://findproperties.ae/for-rent/residential-villa/ajman">Villas for rent in Ajman</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman">Villas for sale in Ajman</a></li>
                      <li><a href="https://findproperties.ae/for-sale/apartments/ajman">flats for sale in Ajman</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/ajman">studio for rent in Ajman monthly</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/ajman">studio for rent in ajman yearly</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-bustan">flats for sale in Al Bustan</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-nuaimiya">Apartments for rent in Al nuamiya</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-rashidiya">apartments for rent in al rashidiya</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-rent/apartments/ajman/corniche-ajman">Apartments for rent in ajman corniche</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/ajman/al-sawan">Apartments for rent in Al Sawan</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-land/ajman">land for sale in ajman</a></li>
                      <li><a href="https://findproperties.ae/for-sale/properties/ajman">properties for sale in ajman</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman/al-yasmeen">Villa for sale in Al Yasmeen</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                    <ul>
                      <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman/al-rawda">villa for sale in al rawda</a></li>
                      <li><a href="https://findproperties.ae/for-sale/residential-villa/ajman/al-zahya">villa for sale in al zahya</a></li>
                      <li><a href="https://findproperties.ae/for-sale/apartments/ajman/al-nuaimiya">Apartments for sale in Al Nuamiya</a></li>
                      <li><a href="https://findproperties.ae/for-sale/apartments/ajman/al-bustan">Apartments for sale in al bustan</a></li>
                      <li><a href="https://findproperties.ae/for-sale/apartments/ajman/al-nuaimiya/city-tower">Apartments for sale in city tower</a></li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#pills-umq">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-4">
                    <b>{i18next.t("popularsearches")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#pills-rak">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-4">
                    <b>{i18next.t("popularsearches")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                    <ul>
                      <li>Properties for rent in UAE</li>
                      <li>Apartments for rent in UAE</li>
                      <li>Apartments for rent in Dubai</li>
                      <li>Apartments for rent in UAE</li>
                      <li>Apartments for rent in Dubai</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#pills-fuj">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-4">
                    <b>{i18next.t("popularsearches")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("popularareas")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingareas")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 col-lg-4"><b>{i18next.t("trendingsearches")}</b>
                    <ul>
                    <li><a href="https://findproperties.ae/for-rent/properties/dubai">Properties for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai">Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/studio-apartments/dubai">Studio Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Furnished Apartments for rent in Dubai</a></li>
                      <li><a href="https://findproperties.ae/for-rent/apartments/dubai"> Flats for rent in Dubai</a></li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </footer>
    );
  }
}
