import React, { Suspense, lazy, Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { Helmet } from 'react-helmet';
import "./App.css";
import HeaderComponent from "./pages/CommonPageComponets/HeaderComponent";
import FooterComponent from "./pages/CommonPageComponets/FooterComponent";
import PrivateRoute from "./Components/protectRoutes/PrivateRoute";
// import PublicRoute from "./Components/PublicRoute";
// import i18next from "i18next";
import { withTranslation } from 'react-i18next';
import { languageDetector } from "./translations/languageDetector";
import Properties from './pages/Properties';
import Home from './pages/Home';
const SingleProperty = lazy(() => import('./pages/Property/SingleProperty/SingleProperty'));

// import analytics from './analytics';
const TagManager = lazy(() => import('react-gtm-module'));
// const Properties = lazy(() => import('./pages/Properties'));
// const Home = lazy(() => import('./pages/Home'));
const Agencies = lazy(() => import('./pages/Agency/Agencies'));
const SingleAgency = lazy(() => import('./pages/Agency/SingleAgency'));
const SingleAgent = lazy(() => import('./pages/Agent/SingleAgent'));
const AllBlogs = lazy(() => import('./Blog/AllBlogs'));
const SingleBlog = lazy(() => import('./Blog/SingleBlog'));
const AreaGuide = lazy(() => import('./Blog/AreaGuide'));
const BuildingGuide = lazy(() => import('./Blog/BuildingGuide'));
// const SchoolGuide = lazy(() => import('./Blog/Tips'));
const ProfileSetting = lazy(() => import('./pages/Admin/Settings/Profile/ProfileSetting'));
const Messages = lazy(() => import('./pages/Admin/Settings/Profile/Messages'));
const Favourites = lazy(() => import('./pages/Admin/Settings/Profile/Favourites'));
const SendProperty = lazy(() => import('./pages/Admin/Settings/Profile/SendProperty'));
const NotFound = lazy(() => import('./NotFound'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const LostPassword = lazy(() => import('./pages/CommonPageComponets/ForgotPassword'));
const TouristDestination = lazy(() => import('./Blog/TouristDestination'));
const Tips = lazy(() => import('./Blog/Tips'));
const AddProperty = lazy(() => import('./dashboard/addProperty'));
const MyAds = lazy(() => import('./dashboard/myAds'));
const EditAgentProperty = lazy(() => import('./dashboard/EditAgentProperty'));
const ReserPassword = lazy(() => import('./pages/CommonPageComponets/ResetPassword'));
const Terms = lazy(() => import('./Components/Terms'));

const User = lazy(() => import('./pages/CommonPageComponets/User'));

const lang = languageDetector();

const tagManagerArgs = {
  gtmId: "G-TDM76TL7XX",
};


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newpageroute: "/for-rent/apartment",
      loaded: false,
      propertytypes: [],
    };
    this.isComponentMounted = false;
  }
  componentWillUnmount() {
    this.isComponentMounted = false;
  }
  componentDidMount() {
    this.isComponentMounted = true;
    <Suspense>
      TagManager.initialize(tagManagerArgs);
    </Suspense>
    // analytics.initGA();
    // document.addEventListener("DOMContentLoaded", function(event) {
    //   setTimeout(function() {
    //     const tagManagerArgs = {
    //       gtmId: 'GTM-TDM76TL7XX'
    //     };
    //     TagManager.initialize(tagManagerArgs);
    //   }, 3000); // Delay by 3 seconds
    // });
    // analytics.logPageView();
  }

  render() {
    const rtlClass = lang === 'ar' ? 'rtl' : 'ltr';
    return (
      <div id="main-wrapper"
        style={{ direction: rtlClass }}
        className="show"
        dir={rtlClass}>
        <Router>
          <HeaderComponent lang={lang} />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/login/" component={() => <User lang="ar" />} />
              <Route path={"/single-property/:id"} component={SingleProperty} />
              <Route path={"/ar/single-property/:id"} component={SingleProperty} />
              <Route exact path="/:language?/:purpose(for-rent|for-sale|for-sharing)/:propertyType/:emirate/:area?/:subarea?" component={Properties} />
              <Route path={lang === "en" ? "/agency" : "/ar/agency"} component={Agencies} />
              <Route path={lang === "en" ? "/agencies/:id" : "/ar/agencies/:id"} component={SingleAgency} />
              <Route path={lang === "en" ? "/agents/:id" : "/ar/agents/:id"} component={SingleAgent} />
              <Route path={lang === "en" ? "/blogs/:id" : "/ar/blogs/:id"} component={SingleBlog} />
              <Route path={lang === "en" ? "/blogs" : "/ar/blogs"} component={AllBlogs} />
              <Route path={lang === "en" ? "/term-and-conditions" : "/ar/term-and-conditions"} component={TermsAndConditions} />
              <Route path={lang === "en" ? "/area-guide" : "/ar/area-guide"} component={AreaGuide} />
              <Route path={lang === "en" ? "/building-guide" : "/ar/building-guide"} component={BuildingGuide} />
              <Route path={lang === "en" ? "/tourist-destination" : "/ar/tourist-destination"} component={TouristDestination} />
              <Route path={lang === "en" ? "/tips" : "/ar/tips"} component={Tips} />
              <Route path={lang === "en" ? "/contact" : "/ar/contact"} component={ContactUs} />
              <Route path={lang === "en" ? "/forgot-password" : "/ar/forgot-password"} component={LostPassword} />
              <Route path={lang === "en" ? "/reset-password/:id" : "/ar/reset-password/:id"} component={ReserPassword} />
              <PrivateRoute path={lang === "en" ? "/message" : "/ar/message"} component={Messages} />
              <PrivateRoute path={lang === "en" ? "/editagentProperty/:id" : "/ar/editagentProperty/:id"} component={EditAgentProperty} />
              <PrivateRoute path="/add-listing" component={AddProperty} />
              <PrivateRoute path="/ar/add-listing" component={AddProperty} />
              <PrivateRoute path={lang === "en" ? "/my-ads" : "/ar/my-ads"} component={MyAds} />
              <PrivateRoute path={lang === "en" ? "/profileSetting" : "/ar/profileSetting"} component={ProfileSetting} />
              <PrivateRoute path={lang === "en" ? "/messages" : "/ar/messages"} component={Messages} />
              <PrivateRoute path={lang === "en" ? "/favourites" : "/ar/favourites"} component={Favourites} />
              <PrivateRoute path={lang === "en" ? "/sendProperty" : "/ar/sendProperty"} component={SendProperty} />
              <Route exact path="/" component={() => <Home lang="en" />} />
              <Route exact path="/ar/" component={() => <Home lang="ar" />} />
              <Route exact path={"/terms-and-conditions"} component={Terms} />
              <Route component={NotFound} />

            </Switch>
          </Suspense>
          <FooterComponent lang={lang} />

        </Router>
      </div>
    );
  }
}

export default withTranslation()(App);
