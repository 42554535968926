import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import {languageDetector} from './translations/languageDetector'

import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter as Router } from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const lang = languageDetector();

document.documentElement.lang = lang;
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App lang={lang}/>
    </PersistGate>
  </Provider>, rootElement);
} else {
  render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App lang={lang}/>
          </Router>
        </PersistGate>
      </I18nextProvider>
    </Provider>, rootElement);
}
serviceWorkerRegistration.register();
reportWebVitals();
