// src/reducers/languageSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Function to get the initial language from the URL
const getInitialLanguage = () => {
  const pathname = window.location.pathname;
  if(pathname.startsWith('/ar')){
    console.log("path name is arabic")
  }
  return pathname.startsWith('/ar') ? 'ar' : 'aen';
};

const initialState = {
  language: getInitialLanguage(),
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;